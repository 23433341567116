import { useSuspenseQuery } from "@tanstack/react-query";
import { DollarSign, LineChart, TrendingUp, Users } from "lucide-react";
import ErrorDisplay from "./ErrorDisplay";
import LoadingSpinner from "./LoadingSpinner";
import { StatCard } from "./StatCard";
import { MetricCharts } from "./MetricChart";
import { historicalQueryOptions, latestQueryOptions } from "../api";

function formatLargeMoney(moneyStr: string): string {
	const number = parseFloat(moneyStr.replace(/[$,]/g, ""));
	const scales: [number, string][] = [
		[1e12, "trillion"],
		[1e9, "billion"],
		[1e6, "million"],
		[1e3, "thousand"],
		[1, ""],
	];

	for (const [scale, suffix] of scales) {
		if (Math.abs(number) >= scale) {
			const scaled = number / scale;
			return `$${scaled.toFixed(2)} ${suffix}`;
		}
	}

	return `$${number.toFixed(2)}`;
}

function Dashboard() {
	const {
		data: latest,
		isLoading,
		error,
	} = useSuspenseQuery(latestQueryOptions);

	const {
		data: historicalData,
		isLoading: historicalLoading,
		error: historicalError,
	} = useSuspenseQuery(historicalQueryOptions);
	console.log(historicalData);

	if (isLoading || historicalLoading) {
		return <LoadingSpinner />;
	}

	if (error || !latest || historicalError || !historicalData) {
		return (
			<ErrorDisplay message="Failed to load economic metrics. Please try again later." />
		);
	}

	return (
		<div className="p-6">
			<div className="max-w-7xl mx-auto flex flex-col gap-6">
				<header className="mb-8">
					<h1 className="text-3xl font-bold text-gray-900">
						Economic Dashboard
					</h1>
					<p className="text-gray-600">Key Economic Indicators Overview</p>
					<p className="text-sm text-gray-500 mt-2">
						Last ran: {new Date(latest.timestamp).toLocaleString()}
						{/* TODO: Move to tooltip */}
						<span className="text-xs text-gray-500 italic ml-2">
							Note this is when the data was fetched, sometimes the sources are
							not updated as often
						</span>
					</p>
				</header>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
					<StatCard
						title="Unemployment Rate"
						value={latest.unemployment}
						date={latest.unemployment_date}
						icon={<Users className="w-6 h-6" />}
						color="blue"
					/>
					<StatCard
						title="Federal Debt"
						value={formatLargeMoney(latest.debt)}
						date={latest.debt_date}
						icon={<DollarSign className="w-6 h-6" />}
						color="red"
					/>
					<StatCard
						title="CPI (Urban Consumers)"
						value={latest.inflation}
						date={latest.inflation_date}
						icon={<TrendingUp className="w-6 h-6" />}
						color="green"
					/>
					<StatCard
						title="GDP"
						value={latest.gdp}
						date={latest.gdp_date}
						icon={<LineChart className="w-6 h-6" />}
						color="purple"
					/>
				</div>
				<div className=" md:grid-cols-2 gap-6">
					<MetricCharts data={historicalData} />
				</div>
			</div>
		</div>
	);
}

export default Dashboard;

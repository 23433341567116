import { queryOptions } from "@tanstack/react-query";
import { BASE_API_URL } from ".";
import type { Metrics, MetricSnapshot } from "./types";

const HISTORICAL_URL = "api/history";

let t: keyof Metrics;
export const historicalQueryOptions = queryOptions({
	queryKey: ["history"],
	queryFn: () => fetchHistoricalData(),
});

export async function fetchHistoricalData() {
	// Simulate API delay
	const response = await fetch(`${BASE_API_URL}${HISTORICAL_URL}`);
	const data = await response.json();
	return data as MetricSnapshot[];
}

import React from "react";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from "recharts";
import type {
	MetricSnapshot,
	SnapshotMetricEntry,
	MetricKeys,
} from "../api/types";

interface MetricsGridProps {
	data: MetricSnapshot[];
}

interface MetricConfig {
	key: MetricKeys;
	name: string;
	color: string;
	format: (v: number) => string;
}

interface MetricChartProps {
	data: Array<{
		timestamp: string;
		[key: string]: number | string;
	}>;
	config: MetricConfig;
}

const MetricChart: React.FC<MetricChartProps> = ({ data, config }) => (
	<div className="p-4 w-full border rounded-lg">
		<h3 className="text-lg font-semibold mb-2 text-gray-700">{config.name}</h3>
		<div className="h-48 w-full">
			<ResponsiveContainer width="100%" height="100%">
				<LineChart
					data={data}
					margin={{ top: 10, right: 30, left: 10, bottom: 10 }}
				>
					<CartesianGrid strokeDasharray="3 3" opacity={0.1} />
					<XAxis
						dataKey="timestamp"
						tick={{ fontSize: 12 }}
						tickFormatter={(value) => {
							const date = new Date(value);
							return `${date.getMonth() + 1}/${date.getFullYear()}`;
						}}
					/>
					<YAxis
						tick={{ fontSize: 12 }}
						domain={["auto", "auto"]}
						tickFormatter={(value) => {
							if (value >= 1000) return `${(value / 1000).toFixed(1)}k`;
							return value.toFixed(1);
						}}
					/>
					<Tooltip
						formatter={(value: number) => config.format(value)}
						labelFormatter={(label: string) =>
							new Date(label).toLocaleDateString()
						}
					/>
					<Line
						type="monotone"
						dataKey={config.key}
						stroke={config.color}
						strokeWidth={2}
						dot={{ r: 3 }}
						activeDot={{ r: 5 }}
						isAnimationActive={false}
					/>
				</LineChart>
			</ResponsiveContainer>
		</div>
	</div>
);

const METRIC_CONFIGS: MetricConfig[] = [
	{
		key: "unemployment",
		name: "Unemployment Rate",
		color: "#8884d8",
		format: (v) => `${v}%`,
	},
	{
		key: "gdp",
		name: "GDP (Trillions)",
		color: "#82ca9d",
		format: (v) => `$${v.toFixed(2)}T`,
	},
	{
		key: "inflation",
		name: "Inflation",
		color: "#ffc658",
		format: (v) => v.toFixed(2),
	},
	{
		key: "debt",
		name: "National Debt (Trillions)",
		color: "#ff7300",
		format: (v) => `$${v.toFixed(2)}T`,
	},
];

export const MetricCharts: React.FC<MetricsGridProps> = ({ data }) => {
	const transformedData = data
		.map((item) => ({
			timestamp: new Date(item.timestamp).toLocaleDateString(),
			unemployment: item.metrics.unemployment.value,
			gdp: item.metrics.gdp.value / 1000,
			inflation: item.metrics.inflation.value,
			debt: item.metrics.debt.value / 1e12,
		}))
		.reverse();

	return (
		<div className="grid grid-cols-2 gap-4 w-full">
			{METRIC_CONFIGS.map((config) => (
				<MetricChart key={config.key} data={transformedData} config={config} />
			))}
		</div>
	);
};
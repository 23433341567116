import { createFileRoute } from "@tanstack/react-router";
import Dashboard from "../components/Dashboard";
import { historicalQueryOptions, latestQueryOptions } from "../api";

export const Route = createFileRoute("/")({
	loader: ({ context: { queryClient } }) => {
		queryClient.ensureQueryData(latestQueryOptions);
		queryClient.ensureQueryData(historicalQueryOptions);
	},
	component: Dashboard,
});

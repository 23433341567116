import { BASE_API_URL, EconomicData } from ".";
import { queryOptions } from "@tanstack/react-query";

const LATEST_URL = "api/latest";

export const latestQueryOptions = queryOptions({
	queryKey: ["latest"],
	queryFn: () => fetchLatestStats(),
});

export const fetchLatestStats = async (): Promise<EconomicData> => {
	const response = await fetch(`${BASE_API_URL}${LATEST_URL}`);
	const data = await response.json();
	//TODO: add error handling and type validation
	return data;
};

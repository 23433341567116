export interface EconomicData {
	debt: string;
	debt_date: string;
	gdp: string;
	gdp_date: string;
	inflation: string;
	inflation_date: string;
	timestamp: string;
	unemployment: string;
	unemployment_date: string;
}
export const BASE_API_URL =
	//@ts-expect-error - its there
	process.env.NODE_ENV === "development"
		? "http://localhost:8787/"
		: "https://api.us-econ.dev/";

export * from "./latest";
export * from "./historical";

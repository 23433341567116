import { AlertCircle } from 'lucide-react';
import type React from 'react';

interface ErrorDisplayProps {
  message: string;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ message }) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="bg-white p-8 rounded-lg shadow-sm max-w-md w-full">
        <div className="flex items-center justify-center text-red-500 mb-4">
          <AlertCircle size={48} />
        </div>
        <h2 className="text-xl font-semibold text-center text-gray-900 mb-2">
          Error Loading Data
        </h2>
        <p className="text-gray-600 text-center">{message}</p>
      </div>
    </div>
  );
};

export default ErrorDisplay;

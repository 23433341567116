import { format } from 'date-fns';
import type React from 'react';

interface StatCardProps {
  title: string;
  value: string;
  date: string;
  icon: React.ReactNode;
  color: 'blue' | 'red' | 'green' | 'purple';
}

const colorClasses = {
  blue: 'bg-blue-50 text-blue-600',
  red: 'bg-red-50 text-red-600',
  green: 'bg-green-50 text-green-600',
  purple: 'bg-purple-50 text-purple-600',
};

export const StatCard: React.FC<StatCardProps> = ({
  title,
  value,
  date,
  icon,
  color,
}) => {
  const formattedDate = format(new Date(date), 'MMM d, yyyy');

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between">
        <span className={`p-2 rounded-lg ${colorClasses[color]}`}>{icon}</span>
      </div>
      <h3 className="mt-4 text-2xl font-semibold text-gray-900">{value}</h3>
      <p className="text-sm text-gray-600 mt-1">{title}</p>
      <p className="text-xs text-gray-500 mt-2">As of {formattedDate}</p>
    </div>
  );
};
